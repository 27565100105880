import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import { Alert, Button, Divider, Flex, Form, Input, Space, Typography } from 'antd'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useAuth } from '../contexts/AuthContext'
import { WEBFLOW_PRICING_PAGE_URL } from './LandingContainer'
const { Title, Text, Paragraph } = Typography

const LoginForm = () => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const router = useRouter()
    const email = (router.query?.offerTeamEmail as string) || (router.query?.email as string) || ''

    const { handleSignIn } = useAuth()

    const [form] = Form.useForm()

    useEffect(() => {
        if (email && form) {
            form.setFieldValue('email', email)
        }
    }, [email, form])

    const signIn = async (values) => {
        const { email, password } = values

        setLoading(true)
        try {
            await handleSignIn(email, password)

            if (router.query?.talentRedirect) {
                await router.push(decodeURIComponent(router.query?.talentRedirect as string))
            }
        } catch (err) {
            // console.log(err)
            setLoading(false)
            setError(err.message)
        }
    }

    const handleGetAccessNow = () => {
        if (router.query?.talentRedirect) {
            router.push(`/signup?talentRedirect=${encodeURIComponent(router.query.talentRedirect as string)}`)
        } else {
            router.push(WEBFLOW_PRICING_PAGE_URL)
        }
    }

    return (
        <Flex className="w-100" gap="middle" vertical>
            <Space direction="vertical" size="middle">
                <Title level={1}>Sign In</Title>
                <Form form={form} onFinish={signIn}>
                    <Form.Item
                        name="email"
                        rules={[{ required: true, type: 'email', message: 'Please input your email' }]}
                    >
                        <Input placeholder="Email" disabled={!!email} />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{ required: true, type: 'string', message: 'Please input your password' }]}
                        className="mb-1"
                    >
                        <Input.Password
                            type="password"
                            placeholder="Password"
                            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        />
                    </Form.Item>
                    <Paragraph className="mb-3">
                        <Link href="/forgot-password">Forgot your password?</Link>
                    </Paragraph>
                    <Form.Item>
                        <Button className="w-100" type="primary" htmlType="submit" loading={loading}>
                            Sign In
                        </Button>
                    </Form.Item>
                    {error && <Alert type="error" message={error} />}
                    <Divider plain>Need an account?</Divider>
                    <Button className="w-100" type="default" onClick={handleGetAccessNow}>
                        Get Access Now
                    </Button>
                </Form>
            </Space>
        </Flex>
    )
}

export default LoginForm
